"use strict";

class SSUtilService {

    constructor($log, $localStorage, $sessionStorage, $cookies, $window, $sce, $parse, SSAdminPasswordService) {
        this.$log = $log;
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
        this.$cookies = $cookies;
        this.$window = $window;
        this.$sce = $sce;
        this.$parse = $parse;

        this.SSAdminPasswordService = SSAdminPasswordService;

        this.PAYMENT_TYPE_01_BOOKING_FEE = 1;
        this.PAYMENT_TYPE_02_DEPOSIT = 2;

        this.dayShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        this.dayFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        this.monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        this.monthsFull = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        this.COUNTRY_ID = { // TODO: Check country code from global object if exist
            AUSTRALIA: 36,
            SINGAPORE: 702,
            THAILAND: 764
        }

        this.localStorageUUID();
    }

    localStorageUUID() {
        const self = this;

        let uuid = self.getFromLocalStorage('UUID');
        if (!uuid) {
            uuid = self.uuid();
            self.saveToLocalStorage('UUID', uuid);
        }

        self.$cookies.put('UUID', uuid);

        return uuid;
    }

    /**
     * Logs the error
     * @param str
     */
    log(str) {
        this.$log.log(str);
    }

    /**
     * Logs the error
     * @param str
     */
    logError(str) {
        this.$log.error(str);
    }

    /**
     * Generates random string.
     *
     * @param {number} length - default is 50
     * @returns {string}
     */
    getRandomName(length = 50) {
        return generateCode(length, CODE_DIGITS + CODE_LCHARS + CODE_UCHARS);
    }

    uuid() {
        var d = new Date().getTime();
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    getFileExtension(fileName) {
        if (!fileName) {
            return null;
        }

        let ext = fileName.split('.').pop();
        if (ext == 'jpeg') {
            return 'jpg';
        }
        return ext;
    }

    //TODO: Remove it and use SSAppService.getAssetUrl()
    makeAssetUrl(uri) {
        if (!uri) return;
        if (uri.indexOf('placehold') !== -1) return uri;
        return SHOWSUITE.AWS.base_s3_url + '/' + uri;
    }

    humanReadableTimeCountDown(inDate) {

        //make it generic to CountUp and Down ... to handle 'ago' as well.

        let delta = Math.round((inDate - new Date) / 1000);

        if (delta < 0) {
            return null;
        }

        let minute = 60,
            hour = minute * 60,
            day = hour * 24,
            week = day * 7;

        let fuzzy;

        if (delta < day) {
            fuzzy = Math.floor(delta / hour) + ' hours';
        } else if (delta < week) {
            fuzzy = Math.floor(delta / day) + 'days';
        } else {
            fuzzy = Math.floor(delta / 30) + 'months';
        }

        return fuzzy;
    }

    // Local Storage
    /**
     * Safely define a key in localstorage.
     * @param defaultKey
     */
    defineStorageDefault(defaultKey, value = null) {
        if (_.has(this.$localStorage, defaultKey)) {
            return this.$localStorage[defaultKey];
        }

        if (!value) {
            this.$localStorage[defaultKey] = {};
        } else {
            this.$localStorage[defaultKey] = value;
        }

        return this.$localStorage[defaultKey];
    }

    /**
     * Save value in localstorage.
     * @param key
     */
    saveToLocalStorage(key, val) {
        this.$localStorage[key] = val;
    }

    /**
     * Remove value from $localstorage.
     * @param key
     */
    deleteFromLocalStorage(key) {
        delete this.$localStorage[key];

    }

    /**
     * Get value from $localstorage.
     * @param key
     */
    getFromLocalStorage(key) {
        return this.$localStorage[key];
    }
    // Local Storage

    // Session Storage
    saveToSessionStorage(key, val) {
        this.$sessionStorage[key] = val;
    }

    deleteFromSessionStorage(key) {
        delete this.$sessionStorage[key];
    }

    getFromSessionStorage(key) {
        return this.$sessionStorage[key];
    }
    // Session Storage

    // Parameters:
    //   totalPages:     total number of pages
    //   page:           current page
    //   maxLength:      maximum size of returned array
    getPageList(totalPages, page, maxLength) {
        if (maxLength < 5) {
            console.log("maxLength must be at least 5");
        }

        function range(start, end) {
            return Array.from(Array(end - start + 1), (_, i) => i + start);
        }

        var sideWidth = maxLength < 9 ? 1 : 2;
        var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
        var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
        if (totalPages <= maxLength) {
            // no breaks in list
            return range(1, totalPages);
        }
        if (page <= maxLength - sideWidth - 1 - rightWidth) {
            // no break on left of page
            return range(1, maxLength - sideWidth - 1).concat(
                0,
                range(totalPages - sideWidth + 1, totalPages)
            );
        }
        if (page >= totalPages - sideWidth - 1 - rightWidth) {
            // no break on right of page
            return range(1, sideWidth).concat(
                0,
                range(
                    totalPages - sideWidth - 1 - rightWidth - leftWidth,
                    totalPages
                )
            );
        }
        // Breaks on both sides
        return range(1, sideWidth).concat(
            0,
            range(page - leftWidth, page + rightWidth),
            0,
            range(totalPages - sideWidth + 1, totalPages)
        );
    }

    getFullName(user){
        const self = this;

        let name = "";
        if (user && (user.first_name || user.last_name || user.display_name || user.name || user.legal_name)){
            if (user.name){  // In case, if buyer is 'company'
                name = user.name;
            } else if (user.first_name || user.last_name){
                name = (user.first_name? user.first_name + " " : "") + user.last_name || "";
            }
            if (!name.trim() && user.display_name){
                name = user.display_name || "";
            } else if (!name.trim() && user.legal_name){
                name = user.legal_name || "";
            }

        }

        return name.trim()? name.trim() : "";
    }

    makeDictionaryFromArray(arr, keyToConsider) {
        let arrToReturn = [];

        _.forEach(arr, function (arrItem) {
            if (_.has(arrItem, keyToConsider)) {
                arrToReturn[arrItem.keyToConsider] = arrItem;
            }
        });

        return arrToReturn;
    }

    findValueFromArray(arr, key, value) {
        let itemToReturn = null;

        _.forEach(arr, function (arrItem) {
            if (_.has(arrItem, key) && arrItem[key] == value) {
                itemToReturn = arrItem;
                return false;
            }
        });

        return itemToReturn;
    }

    getURLParameter(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    }

    addOrUpdateUrlParam(url, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
            hash;

        if (re.test(url)) {
            if (typeof value !== 'undefined' && value !== null)
                return url.replace(re, '$1' + key + "=" + value + '$2$3');
            else {
                hash = url.split('#');
                url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
                if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                    url += '#' + hash[1];
                return url;
            }
        }
        else {
            if (typeof value !== 'undefined' && value !== null) {
                var separator = url.indexOf('?') !== -1 ? '&' : '?';
                hash = url.split('#');
                url = hash[0] + separator + key + '=' + value;
                if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                    url += '#' + hash[1];
                return url;
            }
            else
                return url;
        }
    }

    updateWindowUrl(urlPath) {
        this.$window.history.pushState({}, null, urlPath);
    };

    groupBy(collection, property) {
        var i = 0, val, index,
            values = [], result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1)
                result[index].push(collection[i]);
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    }


    /*
     * Parse the units array to format data floor-wise and fill the missing units as well
     */
    getFloorWiseUnits(units) {

        let unitRange = [];      // holds all the unique unit numbers [1, 2, .. , 10]

        let data = [];

        for (let i = 0; i < units.length; i++) {

            let floor_no = units[i].floor_no;

            if (!data[floor_no]) {
                data[floor_no] = {};
            }
            data[floor_no][units[i].unit_no] = units[i];

            // update the units' number range array
            if (unitRange.indexOf(units[i].unit_no) == -1) {
                unitRange.push(units[i].unit_no);
            }

        }

        unitRange.sort(function (a, b) {
            return a - b
        });

        for (let floorKey in data) {

            let floorUnits = data[floorKey];
            for (let rangeKey in unitRange) {
                let range = unitRange[rangeKey];
                if (!floorUnits[range]) {
                    floorUnits[range] = {unit_no: range, floor_no: floorKey, category: 0};
                }
            }

        }

        data.sort(function (a, b) {
            let unitA = a[unitRange[1]];
            let unitB = b[unitRange[1]];
            return (unitB ? unitB.floor_no : 0) - (unitA ? unitA.floor_no : 0)
        });
        return data;

    }


    openUrlInNewTab(url) {
        if (!url) return;

        if (startsWith(url, 'mailto:')) {
            this.$window.open(url, '_self')
        } else {
            this.$window.open(url, '_blank').focus();
        }
    }


    openUrlInNewTabForPrint(url) {
        var newWin = this.$window.open(url, '_blank');
        newWin.focus();
        newWin.print();
    }

    geoIpLookup(callback) {
        jQuery.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
            callback((resp && resp.country) ? resp.country : "");
        });
    }

    getUnitNumberStr(unit_num) {
        if (unit_num && unit_num.toString().length == 1) {
            return '0' + unit_num;
        }
        return unit_num;
    }

    /**
     * Returns unit number in #00-00 format.
     * @param unit The unit object
     * @param withoutHash
     * @returns {string}
     */
    getFullUnitNumber(unit, withoutHash) {
        if (!unit) {
            return;
        }

        if (unit.house_no) {
            return unit.house_no;
        }

        let unit_number = this.getUnitNumberStr(unit.floor_no) + '-' + this.getUnitNumberStr(unit.unit_no);

        if (withoutHash !== true)
            unit_number = '#' + unit_number;

        return unit_number;
    }

    /**
     * Returns unit number in #00-00 format.
     * @param unit The unit object
     * @param withoutHash
     * @returns {string}
     */
    getFullUnitNumberWithoutDash(unit, withoutHash) {
        if (!unit) {
            return;
        }

        if (unit.house_no) {
            return unit.house_no;
        }

        let unit_number = this.getFullUnitNumber1(unit, withoutHash);

        if (unit_number) {
            unit_number = unit_number.replace('-', '');
        }

        return unit_number;
    }

    getUnitNumber(unit, withoutHash) {
        if (!unit)
            return;

        let unit_number = this.getUnitNumberStr(unit.unit_no);

        if (withoutHash !== true)
            unit_number = '#' + unit_number;

        return unit_number.toString();
    }

    /**
     * Returns unit number for display.
     * @param unit - Unit object
     * @param withoutHash - boolean, default=false
     * @returns {string}
     */
    getFullUnitNumber1(unit, withoutHash) {
        if (!unit)
            return;

        let unit_number = '';
        if (unit.named_id) {
            unit_number = unit.named_id;
        } else if (unit.house_no) {
            unit_number = unit.house_no;
        } else {
            unit_number = this.getUnitNumberStr(unit.floor_no) + '-' + this.getUnitNumberStr(unit.unit_no);
        }

        if (unit_number.indexOf('#') === -1
            && unit_number.indexOf('-') > -1
            && withoutHash !== true) {
            unit_number = '#' + unit_number;
        }

        return unit_number;
    }

    /**
     * Returns unit number in #00-00 format.
     * @param floor_no
     * @param unit_no
     * @param withoutHash
     * @returns {string}
     */
    getFullUnitNumber2(floor_no, unit_no, withoutHash) {
        if (!(floor_no || unit_no))
            return;

        let unit_number = this.getUnitNumberStr(floor_no) + '-' + this.getUnitNumberStr(unit_no);

        if (withoutHash !== true)
            unit_number = '#' + unit_number;

        return unit_number;
    }

    getObjectKeys(object) {
        return Object.keys(object);
    }

    validatePostcode(country, postcode) {
        if (!postcode) return true;

        let isValid = false;
        if (country == 702) {
            if (postcode.length === 6) {
                let districtCode = parseInt(postcode.substring(0, 2));
                if (districtCode > 0 && districtCode < 29) {
                    isValid = true;
                }
            }
        } else {
            isValid = true;
        }
        return isValid;
    }

    trustSrc(src) {
        const self = this;

        return self.$sce.trustAsResourceUrl(src);
    }

    trustHtml(text) {
        const self = this;

        return self.$sce.trustAsHtml(text);
    }

    /* Date Utils */

    formatDate1(d) {
        let date = this.parseDate(d);

        if (!date) return '';

        return this.monthsFull[date.getMonth()] + ' ' + date.getFullYear();
    }

    formatDate2(d) {
        if (d == '0000-00-00') {
            return '-';
        }
        
        let date = this.parseDate(d);

        if (!date) return '';

        return this.monthsFull[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    }

    formatDate3(d) {
        let date = this.parseDate(d);

        if (!date) return '';

        return this.dayFull[date.getDay()] + ', ' +
            date.getDate() + this.ordinalSuffix(date.getDate()) + ' ' +
            this.monthsFull[date.getMonth()] + ' ' +
            date.getFullYear();
    }

    formatDateDMY(d) {
        let date = this.parseDate(d);

        if (!date) return '';
        
        // 'toLocaleDateString' is not reliable as it will depend upon current system's 'Date/Time' settings
        // return date.toLocaleDateString();

        try{
            return this.formatUtcToLocalDate(date);
        }
        catch(ex){
            console.log(ex);
        }
    }

    formatUtcToLocalDate(date, format = 'DD/MM/YYYY'){
        let gmtDate = moment.utc(date);
 
        if(gmtDate._isValid){
            return gmtDate.local().format(format);
        }
        else{
            return null;
        }
    }

    formatDateToStore(date){
        let gmtDate = moment.utc(date, 'DD/MM/YYYY');

        return gmtDate.local().format('YYYY-MM-DD');
    }

    formatDBDateToDisplay(date){
        let gmtDate = moment.utc(date, 'YYYY-MM-DD');

        return gmtDate.format('DD/MM/YYYY');
    }

    formatDateToStoreWithUtc12(date) {
        const self = this;

        return self.formatDateToStore(date) + ' 12:00:00';
    }

    formatUtcToLocalTime(date){
        
        let gmtDate = moment.utc(date);
 
        if(gmtDate._isValid){
            return gmtDate.local().format('hh:mm A');
        }
        else{
            return null;
        }
    }

    formatTime(d) {
        let date = this.parseDate(d);
        if (!date) return '';

        let hr = date.getHours();
        let min = date.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        let ampm = "am";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "pm";
        } else if (hr < 10) {
            hr = "0" + hr;
        }

        return hr + ":" + min + ampm;
    }

    formatLocalDateTime(d){
        const self = this;

        let date = '--';
        if (d) {
            if (self.formatUtcToLocalDate(d) && self.formatUtcToLocalTime(d)) {
                date = self.formatUtcToLocalDate(d) + ' ' + self.formatUtcToLocalTime(d);
            }
        }

        return date;
    }

    getElapsedTime(utcString) {
        // Convert the UTC string to a local Moment object
        const localTime = moment.utc(utcString).local();

        // Get the current local time as a Moment object
        const currentTime = moment();

        // Calculate the elapsed time in milliseconds
        const elapsedTimeInMilliseconds = currentTime.diff(localTime);

        // Create a Moment duration object from the elapsed time
        const duration = moment.duration(elapsedTimeInMilliseconds);

        // Format the duration as a custom human-readable string (hours and minutes)
        return `${duration.hours()} h & ${duration.minutes()} m`;
    }

    // 10:38am Tuesday, 24th November 2017
    getCurrentDateTime(){
        let currentDate = this.parseDate(new Date());

        return this.formatTime(currentDate) + ' ' + this.formatDate3(currentDate);
    }
    /**
     * Parse the date string and returns the date object.
     *
     * If the
     * If the parameter is a boolean true, then returns new Date object.
     * @param s Date string format - `YYYY-MM-DD hh-mm-ss` or a boolean with value `true`
     * @returns {Date}
     */
    parseDate(s) {
        if (!s) return; // None
        if (s == true) return new Date(); // Current time
        if (s instanceof Date) return s; // Already a Date object
        if (typeof s !== 'string') return; // Not a string

        try {
            var a = s.split(/[^0-9]/);
            if (a.length == 3) {
                return new Date(a[0], a[1] - 1, a[2]);
            } else if (a.length == 6) {
                return new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
            }
        } catch (ex) {
            console.error('Invalid Date Format, expecting YYYY-MM-DD hh-mm-ss');
        }
    }

    ordinalSuffix(i) {
        let j = i % 10;
        let k = i % 100;
        if (j == 1 && k != 11) return "st";
        if (j == 2 && k != 12) return "nd";
        if (j == 3 && k != 13) return "rd";
        return "th";
    }
    /* Date Utils */

    getBrokerRole(role_id){
        let self = this;

        if(!role_id)
            return '';
        
        self.broker_roles = [
            {
                id: 1,
                name: 'Buyer Agent'
            },
            {
                id: 2,
                name: 'Co-broker'
            },
            {
                id: 3,
                name: 'Tagger'
            },
            {
                id: 4,
                name: 'Team Lead'
            },
            {
                id: 5,
                name: 'Sales Consultant'
            }
        ];

        return self.broker_roles.find(x => x.id === role_id).name;
    }

    validateForm(context, contextPrefix, container) {
        let self = this;

        let valid = true;

        if (!context)
            throw new Exception('No context given.');
        if (!contextPrefix)
            contextPrefix = 'ctrl';
        if (!container)
            container = jQuery('body');

        jQuery.each(container.find('input,select,textarea'), function (i, field) {
            field = jQuery(field);

            // Clear the error
            field.parent().removeClass("has-danger");

            // Return if not required field
            let required = field.attr('required');
            if (!required || required === 'false') return;

            // Verify field has ng-model or tel-ng-model specified
            let attr = 'ng-model';
            if (field.attr('type') === 'tel') {
                attr = 'tel-ng-model';
            } else if (field.attr('type') === 'jdate') {
                attr = 'jdate-ng-model';
            }
            let model = self._getModelName(field, attr, contextPrefix);
            if (!model) return;

            // Now validate the non-empty value
            let value = self.$parse(model)(context);
            if (jQuery.isNumeric(value)) {
                let allowzero = field.attr('allow-zero');
                allowzero = allowzero !== undefined && allowzero !== 'false';

                if (!((allowzero && value === 0) || value)) {
                    valid = false;
                }
            } else if (!value) {
                valid = false;
            }

            if (!valid) {
                field.parent().addClass("has-danger");
                field.focus();
                return false;
            }
        });

        return valid;
    }

    _getModelName(field, attr, prefix) {
        let model = field.attr(attr);
        if (!model) return;
        if (prefix) {
            if (!startsWith(model, prefix)) return;
            model = model.substring(prefix.length + 1); // +1 for .
        }
        return model;
    }

    hasChanges(object1, object2) {
        let hasChanges = false;

        jQuery.each(object1, function (k, v) {

            // Ignore Objects
            if (jQuery.isPlainObject(v)) return;
            if (jQuery.isPlainObject(object2[k])) return;

            // Ignore Arrays
            if (jQuery.isArray(v)) return;
            if (jQuery.isArray(object2[k])) return;

            if (object2[k] !== v) {
                hasChanges = true;
                return false; // break;
            }
        });

        return hasChanges;
    }

    isNamedIdValid(str) {
        if (!str)
            return false;
        str = str.trim().toLowerCase();


        // Starts from a character
        // Can only have characters, numbers and dashes (-)
        // All character should be in small case
        let regexp = /^[a-z][a-z0-9\-]+$/;

        return regexp.test(str);
    }

    /**
     * Detect mobile browser
     * Using Regex from http://detectmobilebrowsers.com/
     * @return {boolean}
     */
    isMobile() {
        var check = false;
        (function(a) {
            if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
                check = true;
        })(navigator.userAgent||navigator.vendor||window.opera);
        return check;
    }

    /**
     * Toggle Card block
     * @param $event
     */
    toggleCard($event, verifyAdmin) {
        const self = this;

        let source = jQuery($event.currentTarget);

        let cardHeader = jQuery(source.closest('.card').find('.card-header')[0]);
        let cardBlock = jQuery(source.closest('.card').find('.card-block')[0]);

        if (cardBlock.is(':visible')) {
            cardBlock.slideUp('slow', function () {
                cardHeader.find('i.fa-caret-down').removeClass('fa-caret-down').addClass('fa-caret-left');
            });
        } else {
            if (verifyAdmin) {
                self.SSAdminPasswordService.getVerified(function () {
                    self.toggleCard($event);
                });
                return;
            }

            cardBlock.slideDown('slow', function () {
                cardHeader.find('i.fa-caret-left').removeClass('fa-caret-left').addClass('fa-caret-down');
            });
        }
    }

    clipboardCopy(text) {
        if (text == null)
            return;

        var temp = jQuery('<input>');
        temp.val(text)
        jQuery('body').append(temp);
        temp.select();

        document.execCommand('copy');
        temp.remove();
    }

    getBrowserName() { 

        let browser_name = 'unknown';

        let browser = navigator.userAgent.split(' ').pop();

        if((browser.toLowerCase().indexOf("opera") || browser.toLowerCase().indexOf('opr')) != -1 ) 
        {
          browser_name = 'opera';
        }
        else if(browser.toLowerCase().indexOf("chrome") != -1 )
        {
            // NOTE: There is issue in case of Safari/Chrome
          browser_name = 'chrome';
        }
        else if(browser.toLowerCase().indexOf("safari") != -1)
        {
            // NOTE: There is issue in case of Safari/Chrome
          browser_name = 'safari';
        }
        else if(browser.toLowerCase().indexOf("firefox") != -1 ) 
        {
          browser_name = 'firefox';
        }
        else if((browser.toLowerCase().indexOf("msie") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
        {
          browser_name = 'msie';
        } 
        else if(browser.toLowerCase().indexOf("edge") != -1 ) //IF IE > 10
        {
          browser_name = 'edge';
        }  

        console.log("Browser: " + browser_name);
        
        return browser_name;
    }

    backToTop() {
        jQuery('html,body').animate({ scrollTop: 0 }, 'slow');
    }

    getFileExtension(filename){
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    addOrdinalNumberSuffix(num){
        let arrays = [11,12,13];
        
        if (arrays.indexOf((num % 100)) == -1){
            switch (num % 10) {
                // Handle 1st, 2nd, 3rd
                case 1:  return num + 'st';
                case 2:  return num + 'nd';
                case 3:  return num + 'rd';
            }
        }

        return num + 'th';
    }

    findAndRemoveObject(arr, key, value) {
        return arr.filter(function(obj) {
            return obj[key] !== value;
        });
    }

    returnEllipsisString(string, maxlength) {
        maxlength = maxlength ? maxlength : 10;

        if (string) {
            return string.length > maxlength ? string.substring(0, maxlength - 3) + '...' : string;
        }

        return '';
    }

    validateNumber(event) {
        var theEvent = event || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    validateInteger(event) {
        var theEvent = event || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]/;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    validateSGNRIC(theNric) {
        var nric = [];
        nric.multiples = [2, 7, 6, 5, 4, 3, 2];
        if (theNric.length != 9) {
            return false;
        }
        else{
            return true;
        }

        //implimentation is commented (only length check is used)
        
        var total = 0, count = 0, numericNric;
        var first = theNric.charAt(0).toUpperCase(), last = theNric.charAt(theNric.length - 1).toUpperCase();

        if (first != 'S' && first != 'T') {
            return false;
        }
        /*Above is working*/

        numericNric = theNric.substr(1, theNric.length - 2);

        if (isNaN(numericNric)) {
            return false
        }

        if (numericNric != null) {
            while (numericNric != 0) {
                total += (numericNric % 10) * nric.multiples[nric.multiples.length - (1 + count++)];
                numericNric /= 10;
                numericNric = Math.floor(numericNric);
            }
        }

        var outputs;
        if (first == 'S') {
            outputs = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
        }

        if (first == 'T') {
            outputs = ['G', 'F', 'E', 'D', 'C', 'B', 'A', 'J', 'Z', 'I', 'H'];
        }

        if (last != outputs[total % 11]) {
            return false;
        }

        nric.isNricValid = function (theNric) {
            if (!theNric || theNric == '') {
                return false;
            }
        }
        return true;
    }

    callTelephone(phone_number) {
        this.$window.open('tel:' + phone_number);
    }

    returnCapitalLetters(str) {
        if (str) {
            return str.toUpperCase();
        }
        
        return "";
    }

    returnUnitFacilitiesLabel(facility) {
        if (facility > 1) {
            return facility;
        } else {
            return "Yes"
        }
    }

    formatCurrencyCheckZero(n) {
        if (!n) {
            n = 0;
        }

        return this.formatCurrency(n);
    }

    formatCurrency(n) {
        if (n == null)
            return;

        return 'S$ ' + parseFloat(n).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    formatPercentage(n) {
        if (n == null)
            return;

        return parseFloat(n).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' %' ;
    }

    calculateGST(sale_price, gst_value){
        const self = this;

        if(!sale_price || !gst_value){
            return null;
        }

        let gst_percentage = gst_value/100;

        return (gst_percentage * sale_price).toFixed(2);
    }

    isPositiveInteger(n) {
        return parseFloat(n) === n >>> 0;
    }

    saveDocumentLocal(content, file_name, content_type) {
        if ('Blob' in window) {

            if (!content_type) {
                content_type = 'text/html;charset=utf-8';
            }

            let fileAsBlob = null;
            if (content instanceof Blob) {
                fileAsBlob = content;
            } else {
                fileAsBlob = new Blob([content], {type: content_type});
            }

            if ('msSaveOrOpenBlob' in navigator) {
                navigator.msSaveOrOpenBlob(fileAsBlob, file_name);
            } else {
                let downloadLink = document.createElement('a');
                downloadLink.download = file_name;
                downloadLink.innerHTML = 'Download File';

                if ('webkitURL' in window) {
                    // Chrome allows the link to be clicked without actually adding it to the DOM.
                    downloadLink.href = window.webkitURL.createObjectURL(fileAsBlob);
                } else {
                    // Firefox requires the link to be added to the DOM before it can be clicked.
                    downloadLink.href = window.URL.createObjectURL(fileAsBlob);
                    downloadLink.click(function () {
                        document.body.removeChild(event.target);
                    });

                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                }
                downloadLink.click();
            }
        } else {
            console.log('Your browser does not support the HTML5 Blob.');
        }
    }

    saveImageToLocal(url, fileName){
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";

        try{
            loading(true);
            xhr.onload = function(){
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(this.response);
                var tag = document.createElement('a');
                tag.href = imageUrl;
                tag.download = fileName;
                document.body.appendChild(tag);
                tag.click();
                document.body.removeChild(tag);
                loading(false);
            }
            xhr.send();
        }
        catch(ex){
            loading(false);
            console.log(ex);
        }
    }

    readBlobAsText(blob, callback) {
        const reader = new FileReader();
        reader.readAsText(blob, 'UTF-8'); // Read the blob as text with UTF-8 encoding
        reader.onload = function (event) {
            callback(event.target.result);
        };
    }

    getAmlRiskAssessmentClass(obj){

        if(!(obj && obj.risk_assessment_level)) return;

        if(obj.risk_assessment_level.toLowerCase() == 'low'){
            return 'text-success';
        } else if(obj.risk_assessment_level.toLowerCase() == 'medium'){
            return 'text-warning';
        } else if(obj.risk_assessment_level.toLowerCase() == 'high'){
            return 'text-danger';
        }
    }

    maskNRICPassport(identity_no) {
        let masked_identity_no = angular.copy(identity_no);

        if (masked_identity_no && masked_identity_no.length > 4) {
            masked_identity_no = masked_identity_no.replace(/.(?=.{4,}$)/g, '#');
        }

        return masked_identity_no;
    }
    // WEB-2650 not being used in eoi anymore because need to show full NRIC
    addNRICMask(identity_no) {
        if (identity_no) {
            if (identity_no.length > 4) {
                return "#####" + identity_no.substring(identity_no.length - 4);
            } else {
                return "#####" + identity_no;
            }
        }

        return "";
    }

    formatLocalToUtc(date){
        const self = this;

        try{
            if(date != '' && date != null) {
                // Split date and time on the basis of white-space
                var date_time = date.split(" ");

                var date = date_time[0];
                var time = '';

                if(date_time.length > 1){
                    time = date_time[1];
                    // Server accepts time as 'hh:mm:ss' but jQuery timepicker is 'hh:mm'
                    if(time.split(':').length == 2){
                        time = time + ':00';
                    }
                }

                if(date.includes("-")){
                    var date_format = date + ' ' + time;
                }
                else{
                    var res = date.split("/", 3);
                    var date_format = res[2] + '-' + res[1] + '-' + res[0] + ' ' + time;
                }

                if(date_format) {
                    // var localDate = new Date(self.parseDate(date_format));
                    var utcFormat = moment(date_format).utc().format('YYYY-MM-DD HH:mm:ss');
                }

                return utcFormat;
            }
            else{
                return null;
            }
        }
        catch(ex){
            console.log(ex);
        }
    }

    formatUtcToLocalDateTime(date_time){
        const self = this;

        let parsed_date = '--';

        if(!date_time){
            return parsed_date;
        }

        try{
            parsed_date = moment(moment.utc(date_time).toDate()).local().format('DD/MM/YYYY HH:mm')
        }
        catch(ex){
            console.log(ex);
        }

        return parsed_date;
    }


    getThaiDate(date){
        const self = this;

        if(!date) return '--';

        let arr = date.split('/')
        let year = arr[arr.length-1];

        return arr[0]+'/'+arr[1]+'/'+(parseInt(year)+543).toString();
    }

    getTypedValueLable(amountType, amountValue, thaiProject) {
        if (!amountType
            || amountType == 10 // VALUE_TYPE_PERCENTAGE
        ) {
            return amountValue + '%';
        } else if (amountType == 20) { // VALUE_TYPE_AMOUNT
            return (thaiProject? '฿':'S$') + amountValue.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        }
        return '--';
    }


    calculateTypedValue(baseAmount, amountType, amountValue, roundPrecision=null) {
        let calculatedAmount = null;
        if (!amountType
            || amountType == 10 // VALUE_TYPE_PERCENTAGE
        ) {
            if (amountValue) {
                calculatedAmount = baseAmount * amountValue / 100;
            }
        } else if (amountType == 20) { // VALUE_TYPE_AMOUNT
            calculatedAmount = amountValue;
        }

        if (calculatedAmount > 0
            && roundPrecision > 0
        ) {
            let multiplier = Math.pow(10, roundPrecision);
            calculatedAmount = Math.round(calculatedAmount * multiplier) / multiplier;
        }
        return calculatedAmount;
    }

    validateEmail(email) {
        if (/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)) {
            return (true)
        }
        return (false)
    }

    //-------------------------------------------

    findO(array, findForKey, matchingValue, fieldToGet) {
        if (!array || !findForKey || !matchingValue || !fieldToGet) return;

        let o = array.find(x => x[findForKey] == matchingValue);
        return o ? o[fieldToGet] : null;
    }

    findA(array, index, fieldToGet) {
        if (!array || !index || !fieldToGet) return;

        let o = array[index];
        return o ? o[fieldToGet] : null;
    }

    //-------------------------------------------

    showModal(modalId, visibility=true) {
        let m = $((startsWith(modalId, '#') ? '' : '#' ) + modalId);
        if (visibility) {
            if (!(m.is(':visible'))) m.modal('show');
        } else {
            if (m.is(':visible')) m.modal('hide');
        }
    }

    //-------------------------------------------

    /** WEB-2811 AMS/DMS : AUS country support **/
    isValidNRIC( nric, country ) {
        const self = this;
        const COUNTRY_ID = self.COUNTRY_ID;

        if(!nric) { return false; } // if nric is empty false

        country = country || COUNTRY_ID.SINGAPORE; /* Check default for singapore if not provided */
        if( COUNTRY_ID.AUSTRALIA === country ){
            if(nric.length > 14){ // min length 1, max length 14
                return false;
            }
        } else if( COUNTRY_ID.THAILAND === country ){
            if(nric.length !== 13){ // length must be 13
                return false;
            }
        } else if( COUNTRY_ID.SINGAPORE === country ){
            if(nric.length !== 9){ // length must be 9
                return false;
            }
        }

        return true; // If not found invalid in above conditions return true;
    }

    /** WEB-2811 AMS/DMS : AUS country support **/
    getCountryShortCode(country){
        const self = this;
        const COUNTRY_ID = self.COUNTRY_ID;

        let countryCode;
        switch (country) {
            case COUNTRY_ID.AUSTRALIA:
                countryCode = "AU";
                break;
            case COUNTRY_ID.SINGAPORE:
                countryCode = "SG";
                break;
            case COUNTRY_ID.THAILAND:
                countryCode = "TH"
                break;
            default:
                countryCode = "SG";
                break;
        }

        return countryCode;
    }

    isJSON_(str) {

        if(typeof(str) !== 'string') {
            return false;
        }

        try {
            JSON.parse(str);
            return true;
        } catch(e) {
            return false;
        }
    }

    isJSON(str) {
        if (!str || typeof (str) !== 'string') {
            return;
        }

        str = str.trim();

        let firstChar = str.substr(0, 1);
        let lastChar = str.substr(str.length - 1, 1);

        if ((firstChar === '[' && lastChar === ']')
            || (firstChar === '{' && lastChar === '}')
        ) {
            try {
                let jsonData = JSON.parse(str);
                if (jsonData !== null) {
                    return true; // Return TRUE only here
                }
            } catch (e) {
            }
        }
    }

    convertUnicodeToUtf8String(unicodeString){
        if (!unicodeString || typeof (unicodeString) !== 'string') {
            return unicodeString;
        }

        try{
            return unicodeString.replace(/\\u[\dA-F]{4}/gi, (match) => { return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16)) });
        } catch (e) {
            return unicodeString;
        }
    }

}

SSUtilService.$inject = ['$log', '$localStorage', '$sessionStorage', '$cookies', '$window', '$sce', '$parse', 'SSAdminPasswordService'];
app.service('SSUtilService', SSUtilService);